<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody>
          <CDataTable
            :items="countries"
            :fields="fields"
            column-filter
            :items-per-page="10"
            hover
            pagination
            clickable-rows
            v-on:refresh="getAllCountriesDetail"
            :loading="loading"
          >
          <template #show_details="{item}">
              <td class="py-2">
                <CButton
                  color="info"
                  square
                  size="sm"
                  @click="openModalEvent('update', 'countriesForm', item, 'Düzenle')"
                >
                  Düzenle
                </CButton>
              </td>
            </template>
            <template #shippingActiveFlag="{item}">
              <td>
                <CIcon
                  v-if="item.shippingActiveFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #dhlPltFlag="{item}">
              <td>
                <CIcon
                  v-if="item.dhlPltFlag > 0"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <FormModal 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="modalHandle"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"
    />

  </CRow>
</template>

<script>

import FormModal from '../components/formModal'


export default {
  name: 'Countries',
  data() {
    return {
      openModal: false,
      country: {},
      modalTitle: '',
      actionType: '',
      module: 'operation',
      form: 'countriesForm',
      modalProps: {},
      modalDesc: '',
      fields: [
        { key: 'show_details', label: 'İşlemler'},
        { key: 'id' },
        { key: 'code', label: 'Country Code' },
        { key: 'name', label: 'Country Name' },
        { key: 'nameTr', label: 'NameTr' },
        { key: 'shippingActiveFlag', label: 'ShippingActiveFlag' },
        { key: 'shippingRegionId', label: 'ShippingRegionId' },
        { key: 'taxRegionId', label: 'TaxRegionId' },
        { key: 'displayOrder', label: 'DisplayOrder' },
        { key: 'defaultCurrencyId', label: 'DefaultCurrencyId' },
        { key: 'warehouseAddressId', label: 'WarehouseAddressId' },
        { key: 'dutyFreeAmount', label: 'DutyFreeAmount' },
        { key: 'dhlPltFlag', label: 'DhlPltFlag' },
        { key: 'dhlCountryCode', label: 'DhlCountryCode' },
        { key: 'iossNumber', label: 'IOSSNumber' },
        { key: 'companyName', label: 'ReturnWarehouseAddress' }
      ],
      activePage: 1
    }
  },
  components:{
    FormModal
  },
  computed:{
    countries() {
      let data = this.$store.getters.countries

      data.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          o[key] === null ? (o[key] = '') : (o[key] = o[key])
        })
      })

      return data
    },
    
    loading() {
      return this.$store.getters.operationLoading
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.country) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {

    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    async getAllCountriesDetail() {
      await this.$store.dispatch('countries_list')
    },

    async getAllReturnWarehouseAddressIds() {
      await this.$store.dispatch('returnWarehouse_list')
    },

  
    async modalHandle(item, actionType) {
      let formData = new FormData()

      formData.append('id', item.id)
      formData.append('iossNumber', item.iossNumber)
      formData.append('returnWarehouseAddressId', item.returnWarehouseAddressId)
      formData.append('shippingActiveFlag', item.shippingActiveFlag)

      await this.$store.dispatch('countries_action', formData)
      if(this.$store.getters.operationStatus.success){
        await this.getAllCountriesDetail()
        await this.openModalEvent()
      }
    },
  },
  mounted() {
    this.getAllReturnWarehouseAddressIds()
    this.getAllCountriesDetail()
  }
}
</script>
